<template>
  <div>
    <div class="index1">
      <div class="box1-title">案例展示</div>
      <div class="border"></div>
      <div class="text">
        金华市天华网络科技有限公司成立于2007年，公司主要从事信息行业应用软件开发和技术服务，基于移动互联网与物联网、大数据和云计算等技术，开发大数据管理服务云平台。
      </div>
    </div>
    <div class="index2" ref="box">
      <div v-for="(item, index) in casusList">
        <div>
          <div :id="item.id" class="target-fix"></div>
          <div class="casus_head">
            <img src="../../assets/casus/casus_icon.png" />
            <div class="casus_name">{{ item.category }}</div>
            <i
              :class="
                item.isspread ? 'el-icon-caret-bottom' : 'el-icon-caret-top'
              "
              @click="categorySpread(item)"
            ></i>
            <div v-if="index == 0" class="back_btn">
              <el-button type="primary" @click="goBack()">返回上一级</el-button>
            </div>
          </div>

          <div
            v-for="(system, index2) in item.systemList"
            v-show="item.isspread"
            :id="system.systemId"
          >
            <div :class="index2 % 2 == 0 ? 'casus_content' : 'casus_content2'">
              <div><img :src="system.imgUrl" /></div>
              <div class="system_box">
                <div class="system_name">{{ system.systemName }}</div>
                <div v-for="point in system.points">
                  <div class="point_row" v-if="typeof point == 'string'">
                    <img src="../../assets/homepage/round.png" />
                    <div>{{ point }}</div>
                  </div>
                  <div v-else>
                    <div class="sub_title">{{ point.desc }}</div>
                    <div class="point_row" v-for="pointSub in point.points">
                      <img src="../../assets/homepage/round.png" />
                      <div>{{ pointSub }}</div>
                    </div>
                  </div>
                </div>

                <div class="btn_row">
                  <el-button type="primary" @click="goDetail(item, system)"
                    >查看详情<i class="el-icon-arrow-right el-icon--right"></i
                  ></el-button>
                </div>
              </div>
            </div>
            <div class="divider"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { casusSimpleList } from "../../utils/casus.js";
export default {
  name: "casus",
  data() {
    return {
      casusList: [],
      category: "country",
    };
  },

  mounted() {
    this.category = this.$route.params.category;
    let dataList = casusSimpleList;
    dataList.forEach((item, index) => {
      item.isspread = item.id == this.category;
    });
    this.casusList = dataList;
    let systemObj = JSON.parse(sessionStorage.getItem("casus"));
    if (systemObj) {
      dataList.forEach((item, index) => {
        item.isspread = item.isspread || item.id == systemObj.category;
      });
      this.$nextTick(() => {
        document.querySelector("#" + systemObj.systemId).scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        // window.scrollBy(0, -70);
        sessionStorage.removeItem("casus");
      });
    } else {
      this.$nextTick(() => {
        document.querySelector("#" + this.category).scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        // window.scrollBy(0, -70);
        // let nodes = document.querySelector("#" + this.category);
        // nodes.scrollTo({
        //   top: nodes.offestTop - 70,
        //   behavior: "smooth",
        // });
      });
    }
  },

  methods: {
    categorySpread(item) {
      item.isspread = !item.isspread;
    },
    goDetail(item, system) {
      this.$router.push({
        path: "/casus/detail/" + item.category + "/" + system.systemName,
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.index1 {
  height: 365px;
  background: url("~@/assets/casus/banner.png") no-repeat center center;
  background-size: auto 100%;
  text-align: left;
  padding-left: 340px;
  padding-right: 660px;
}
.box1-title {
  font-size: 28px;
  font-weight: bold;
  color: rgba(52, 111, 255, 1);
  padding-top: 139px;
  margin-bottom: 16px;
}
.border {
  width: 100px;
  height: 10px;
  line-height: 20px;
  border-radius: 10px;
  background-color: rgba(52, 111, 255, 1);
  margin-bottom: 16px;
}

.text {
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  line-height: 20px;
}

.index2 {
  margin: 0 auto;
  margin-top: -40px;
  width: 1500px;
  // height: 665px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  box-shadow: 0px 0px 10px 0px rgba(52, 111, 255, 0.3);
  background-size: 962px 465px;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  padding: 40px 80px;
  box-sizing: border-box;
  .img {
    width: 560px;
    height: 400px;
  }
}
.back_btn {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.divider {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  border: 0.3px dashed rgba(52, 111, 255, 0.2);
}
.casus_head {
  display: flex;
  flex-direction: row;
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  text-align: left;
  align-items: center;
  margin-bottom: 30px;
  font-weight: bold;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}
.casus_content {
  display: flex;
  flex-direction: row;
  img {
    width: 560px;
    height: 400px;
  }
  .system_box {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    flex: 1;
    margin-left: 20px;
  }
  .btn_row {
    margin-top: 20px;
    align-self: flex-end;
    justify-self: end;
  }
  .system_name {
    color: rgba(51, 51, 51, 1);
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .sub_title {
    margin-top: 20px;
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
  }
  .point_row {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
    display: flex;
    img {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      margin-top: 5px;
    }
  }
}

.casus_content2 {
  display: flex;
  flex-direction: row-reverse;
  img {
    width: 560px;
    height: 400px;
  }
  .system_box {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    flex: 1;
    margin-right: 20px;
  }
  .btn_row {
    margin-top: 20px;
    align-self: flex-end;
    justify-self: end;
  }
  .system_name {
    color: rgba(51, 51, 51, 1);
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .sub_title {
    margin-top: 20px;
    text-align: left;
    // margin-left: 20px;
    font-weight: bold;
  }
  .point_row {
    color: rgba(51, 51, 51, 1);
    font-size: 14px;
    text-align: left;
    margin-top: 20px;
    display: flex;
    img {
      width: 10px;
      height: 10px;
      margin-right: 10px;
      margin-top: 5px;
    }
  }
}
.target-fix {
  position: relative;
  top: -70px; // 偏移值
  display: block;
  visibility: hidden; //不让它展示
}
@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
