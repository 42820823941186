export const casusList = {
  未来乡村系统: {
    北山口一颗金佛手: {
      systemId: "beishankou",
      category: "country",
      imgList: [
        require("@/assets/product/beishankou_1.png"),
        require("@/assets/product/beishankou_2.png"),
      ],
      content: [
        { desc: "北山口一颗金佛手系统于2022年立项，预计于2023年正式上线。" },
        {
          desc: "作用：",
          points: [
            "1.首页动态化，操作简便；",
            "2.模块智能化，良好的展现村内历史文化、种植产业、所获荣誉等多方面信息；",
            "3.图表多元化，多种方式展现村内产业在各年度的种植、产量增减、销售等情况，便于村民与游客查看及了解；",
          ],
          imgs: [
            require("@/assets/product/beishankou_1.png"),
            require("@/assets/product/beishankou_2.png"),
          ],
        },
      ],
    },
    智治澧浦: {
      systemId: "lipu",
      category: "country",
      imgList: [
        require("@/assets/product/lipu_1.png"),
        require("@/assets/product/lipu_2.png"),
      ],
      content: [
        {
          desc: "“智治澧浦”项目，通过“两基础、一中心、N应用”的建设规划，围绕党政机关整体智治、数字政府、数字经济、数字社会、数字法治综合应用5个领域，建设智治澧浦数据展示中心、智治澧浦一体化平台、智治安监、矛盾纠纷调解、重点人员管控、智慧求助、垃圾分类、智慧养老、视频综合管理等24个子系统应用。",
        },
        {
          desc: "项目建成后，将使澧浦镇在硬件基础、业务应用和领导决策上有一个大的提升，最终达到多终端智能一体化、共享共治的建设目标。",
          imgs: [
            require("@/assets/product/lipu_1.png"),
            require("@/assets/product/lipu_2.png"),
          ],
        },
      ],
    },
    釜章村乡村数字化项目: {
      systemId: "fuzhang",
      category: "country",
      imgList: [
        require("@/assets/product/fuzhang_1.png"),
        require("@/assets/product/fuzhang_2.png"),
      ],
      content: [
        {
          desc: "金华市金东区岭下镇釜章村“乡村数字化”项目围绕“乡村治理”“乡村服务”“产业共富”的目标，以文旅产业融合发展、乡村治理服务同步发展、绿色农业智慧发展为主线，以推进未来乡村建设试点为着动力，全面推动釜章的产业振兴、文化振兴、生态振兴、农业振兴，高标准、高水平、高质量建成现代化“未来乡村”，努力打造“产业兴、环境美、生活好、服务佳、治理强”的生产生态生活服务治理融合发展的未来乡村浙江样板。",
        },
        {
          desc: "本次建设的釜章村数字化建设以省农业农村局“一统三化九场景”为指导思想，因地制宜，制定了以文旅场景为特色，利用虚拟导览、各类数字化硬件，丰富文旅、产业、服务等应用场景，打造具有釜章特色的应用，本次建设内容包含基础文旅服务系统、未来产业场景信息平台系统，以及针对统建平台各类数据协助更新数据、推广应用，同时对接“2+4”的智慧医疗、社区文化、智慧学习、智慧救助、智慧养老的场景应用，丰富“我的家园”中釜章村的应用内容。",
          imgs: [
            require("@/assets/product/fuzhang_1.png"),
            require("@/assets/product/fuzhang_2.png"),
          ],
        },
      ],
    },
    东叶乡村数字化建设项目: {
      systemId: "dongye",
      category: "country",
      imgList: [
        require("@/assets/product/dongye_1.png"),
        require("@/assets/product/dongye_2.png"),
      ],
      content: [
        {
          desc: "金华市金东区源东乡东叶乡村数字化建设项目以省农业农村局“一统三化九场景”为指导思想，以光南故里为核心，以文旅产业融合发展、乡村治理服务同步发展、绿色农业智慧发展为主线，以推进未来乡村建设试点为着力点，围绕“乡村治理”“乡村服务”“产业共富”的目标，制定了包含文旅、产业、风貌等应用场景的具有东叶特色的应用。",
        },
        {
          desc: "本次建设内容包含基础文旅服务系统、未来产业场景信息平台系统，以及针对统建平台各类数据协助更新数据、推广应用，丰富“我的家园”中东叶村的应用内容。",
          imgs: [
            require("@/assets/product/dongye_1.png"),
            require("@/assets/product/dongye_2.png"),
          ],
        },
      ],
    },
  },
  政务管理系统: {
    武义县户籍式企业信息管理系统: {
      systemId: "huji",
      category: "govement",
      imgList: [],
      content: [
        {
          desc: "武义县户籍式企业信息管理系统于2018年立项，预计2019年7月上线，大概容纳企业3000家。",
          points: [
            "极大节约项目申报企业的办事成本",
            "减轻大量事后处置的成本",
            "便于企业主对运营情况的全面掌握",
            "深入服务工业企业亩产效益评价工作",
          ],
        },
      ],
    },
    金华市企业信息管理平台: {
      systemId: "qiye",
      category: "govement",
      imgList: [],
      content: [
        {
          desc: "金华市企业信息管理平台应用于金华市经济和信息化局、兰溪市经济和信息化局，于2014年立项，2016年正式上线，已纳入系统管理企业2448家，具体分布情况如下：",
          points: [
            "<1>、金东区（含金义新区）：规上和规下企业，882家；",
            "<2>、开发区：仅规上企业，220家；",
            "<3>、婺城区：仅规上企业，172家；",
            "<4>、兰溪市：规上和规下企业，1174家；",
          ],
        },
        {
          desc: "作用",
          points: [
            "1、将大大促进政府主管部门企业监控的时效性和精准性。",
            "2、将为主管部门对企业经济运行监测进行分行业、分区域、分类别地进行纵向与横向、局部与整体的分析提供了方便快捷的渠道。",
            "3、为各级主管部门实现了企业信息共享，为各级部门开展相关工作提供了有效的企业信息支撑。",
            "4、提高了各级主管部门的服务效能，促进了政企互动。通过平台的政策法规发布、咨询服务，提升了政企之间的沟通效率，同时也为企业或行业提供了个性化、精细化的服务。",
            "5、通过系统整合，数据整合，减轻了企业数据重复申报的工作量。",
          ],
        },
      ],
    },
    金华市兰溪市工业企业亩产效益综合评价管理系统: {
      systemId: "mujun",
      category: "govement",
      imgList: [
        require("@/assets/casus/mujun_1.png"),
        require("@/assets/casus/mujun_2.png"),
      ],
      content: [
        {
          desc: "金华市兰溪市工业企业亩产效益综合评价管理系统于2018年立项，于2019年上线。",
        },
        {
          desc: "作用:",
          points: [
            "推进金华市兰溪市经济和信息化局的资源要素配置改革",
            "优化经济运行分析监测",
            "实现企业信息共享",
            "提高管理服务效能",
          ],
        },
      ],
    },
    // 金华市工业企业亩产效益综合评价管理系统: {
    //   imgList: [
    //     require("@/assets/casus/jindong_1.png"),
    //     require("@/assets/casus/jindong_2.png"),
    //   ],
    //   content: [
    //     {
    //       desc: "金华市工业企业亩产效益综合评价管理系统于2016年立项，于2017年正式上线。",
    //     },
    //     {
    //       desc: "作用:",
    //       points: [
    //         "能够推荐金华市经济和信息化局资源要素配置改革",
    //         "优化经济运行分析监测",
    //         "实现企业信息共享",
    //         "提高管理服务效能",
    //       ],
    //     },
    //   ],
    // },
  },
  园区公共服务平台: {
    金东区网络经济服务平台: {
      systemId: "jingji",
      category: "garden",
      imgList: [
        "http://imagepub.chinau8.cn/ce32b510430f47bfa2e7d033e1002490?v=381172",
        "http://imagepub.chinau8.cn/01f63739500b4ebbaa441f1a83e33864?v=198557",
        "http://imagepub.chinau8.cn/cb6aa42540f3429c85092415dab6ab7b?v=902255",
      ],
      content: [
        {
          desc: "金东区网络经济公共服务平台是金东区网络经济发展局根据“十三五”规划开发的综合服务平台，它于2016年4月正式立项，2018年正式上线运营，目前平台已容纳300多家企业。",
        },
        {
          desc: "金东区网络经济公共服务平台的建设，为主管部门和企业搭建了一座沟通便利的桥梁，在整合电商优势资源，提高政府服务水平，促进企业信息交互，满足企业发展需求，促进电子商务的规范快速发展等方面起到重大作用；",
          points: [
            "为主管部门提供了一个对外展示平台，良好地展示了园区建设、园区服务能力，为园区招商提供了便利",
            "平台将各种服务资源有机整合，办事中心，政策宣导，行业动态，政企互动等服务，大大削除了园区分散带来的管理不便问题，对提升政府服务水平有极大帮助",
            "能够引导区内企业通过电子商务手段转型，帮助企业从面向区域市扩大到全国市场，提高区内企业的盈利水平，通过企业的转型升级带动新型人才的需求，改善金东区的人才结构和产业形态",
          ],
        },
      ],
    },
    菜鸟红链e园区党建APP: {
      systemId: "cainiao",
      category: "garden",
      imgList: [],
      content: [
        {
          desc: "园区党建工作，各党支部普遍存在流动党员多、不容易集聚的现状，采用党建+互联网的方式，采用APP，在线上把党员连接起来，通过网上党课、答题互动等多种形式，规范新领域党组织的党日活动，实现各类园区党建工作灵活便捷的管理模式，同时也打造园区职工了解园区重大活动、参与园区管理的平台；",
        },
        {
          desc: "整套APP包含后台管理（PC端）、管理端APP、用户端APP，可根据用户的政治面貌和管辖归属灵活配置菜单权限；",
        },
        {
          desc: "APP功能分为学习园地、党员之家、特色活动、先锋园地、通知公告、人才对接等内容模块，管理员后台实现系统管理、内容维护功能；",
          points: [
            "1、学习园地：可开放给所有注册用户进行在线学习，生成学习记录，并进行学习考核；",
            "2、党员之家：园区各级党支部的信息化管理功能，包括支部管理、党员管理、工作安排、活动会议、党员考评等内容；",
            "3、特色活动：园区特色活动组织、报名、活动开展，可通过图文、视频等形式生动鲜活地生成活动档案；",
            "4、先锋园地：园区各类先进评选展示，树立园区榜样；",
            "5、人才招聘：面向社会的人才招聘对接功能；",
            "6、通知公告",
          ],
        },
      ],
    },
  },
  社区服务管理系统: {
    金都美地社区: {
      systemId: "jindu",
      category: "community",
      imgList: [
        require("@/assets/casus/jindu_1.png"),
        require("@/assets/casus/jindu_2.png"),
      ],
      content: [
        {
          desc: "在社区人口信息管理中，需要从大量的日常管理活动中提取，录入，查看相关信息，以反映社区的真实情况。传统的手工操作方式，易发生数据丢失，统计错误等问题，且劳动强度高，速度慢。使用信息化管理系统可以高速，快捷地完成以上工作，实现数据共享，避免重复劳动，规范信息管理行为，从而提高了管理效率和水平。",
        },
        {
          desc: "　天华社区管理系统主要是为了实现社区管理的文档电子化和管理规范化，将现代化信息技术引入社区管理，为社区管理人员提供方便，快捷，科学，人性化的管理平台，可以极大的方便社区各项工作的进行，节省宝贵的时间、人力资源。",
        },
        {
          desc: "　系统功能主要分为社区基本信息，社区人口管理，社区党建，社区组织，社区文体，社区治安，社会保障，民政工作等模块。通过各种操作方式，可以对各个板块进行录入，查看，修改，删除，搜索和基于各种条件的统计，汇总。同一套系统可以多人协同工作，可以专人设专门权限，专门板块，方便各级部门统筹规划，分配工作，并借此帮助各级社区进行数据统计和汇总分析。3D地图与房屋户籍信息相关联，使管理人员使用软件的时更直观更方便。同时还增加了即时通讯，方便各工作人员在工作上的联系和讨论。",
        },
        {
          desc: "核心功能:",
          points: [
            "1．户籍人口管理:对辖区内房屋进行登记，全面掌握房屋所有人，居住人员，房屋情况。以户籍为核心，对人口档案，家庭资料，人口变动等进行信息化关系，实现户籍人口管理的信息化，规范化。",
            "2．党建文体:以党组织为核心，对党员个人及组织进行管理。包括党员变动，党费缴纳，以及党组织会议，活动，培训等。对辖区内文化团体组织和活动进行登记管理，并对文体活动做相应记录。",
            "3．民政保障:对辖区内失业人员，低保家庭，残疾人员等进行汇总统计，方便相关政策的落实及特殊家庭人员的管理。此外对社区组织，社区企业等单位都有完善的管理模块，实现对相关单位组织的监督管理。",
          ],
        },
        {
          desc: "软件特点",
          points: [
            "1．使用方便采用B/S架构，只需浏览器就可进行所有操作，不需另外安装其他软件",
            "2．安全可靠权限分配灵活，保密严格，确保系统安全性",
            "3．关联，分类，汇总，搜索功能强大 数据一次录入，自动进行关联，分类以及汇总，节约大量工作时间",
            "4．操作简便 支持菜单，导航栏，快捷键操作，满足基层人员不同水平的应用。",
            "5．灵活，可扩展性强 可以随着社区业务的调整变化，依据客户需求对软件组件进行再开发，以适应社区工作的进行及深化。",
          ],
        },
        {
          desc: "目前，金华市多数社区已将天华社区管理系统实际运用到日常工作中，极大的方便了社区管理工作的进行，节省了大量人力，物力以及时间，创新了社区管理新模式，提升了社区管理工作效率，使社区管理步入了现代化信息管理时代。",
        },
        {
          desc: "金华洪湖路社区位于市中心地带，为老式社区，有一定的历史，住户面积较小，布局较为复杂。人口众多，以老年人为主，社区儿童年龄总体偏小，人群状况复杂，人口管理难度较大。社区事业机关，商业网点，公共设施较多，分布松散。",
        },
        {
          desc: "由于社区成立时间较早，存在着管理人员少，设施比较老旧，纸质档案文件存放时间过长等问题。近年来，随着社区人口的日益增多，信息处理也越来越频繁。随之而来的是管理工作日趋复杂繁重，需要耗费大量人力、物力，而社区一直以来使用传统人工的方式管理，这种管理方式存在着许多缺点,如:效率低、保密性差,另外时间一长,将产生大量的文件和数据,这对于查找、更新和维护都带来了不少的困难。这样的机制已经不能适应时代的发展，已愈发成为管理工作的一个瓶颈，已明显不能满足于社区的发展需要，实现社区的信息化管理迫在眉睫。",
        },
        {
          desc: "经过多次的考察与筛选，金华洪湖路社区最终选择了天华社区管理系统。目前天华社区管理系统已在洪湖路社区稳定运行，社区也完成了从传统管理方式到信息化管理的过度，成为了金华市推进新型社区建设的标杆。",
        },
        {
          desc: "此外新狮街道、东孝街道等街道下属的大部分社区都已经全面使用了天华社区管理系统。",
        },
      ],
    },
  },
  在线考试系统: {
    浙江省国际服务外包考试系统: {
      systemId: "guoji",
      category: "test",
      imgList: [],
      content: [
        { desc: "浙江省国际服务外包考试系统于2013年正式立项，于2017年上线。" },
        {
          desc: "信息技术外包服务平台：截止2017年底，共计培训各类学员计4100多人次。",
        },
        {
          desc: "服务外包综合平台：截至2017年累计培训各类人才3万多人，为52家企业提供国际认证培训咨询服务。",
        },
        {
          desc: "电商培训平台：截止到2017年底助理电子商务师共计培训300余人次，颁发证书250人；公司参与金东区农办农民素质提升培训，截止2017年底在金东区辖区进行了600多人次农村电商培训，对象为金东区辖区内农村户籍人员，通过考核548人，并通过金职院的培训督察和相关部门的审计；承接了金东区招商局关于农村电商普及性培训的任务，完成920人次的辖区内电商普及性培训；承接兰溪市商务局和金东区网络经济发展局的电商人才培训任务共计194人次。累计2017年底一共培训电商人才4100余人次。",
        },
      ],
    },
    浙江省电子商务职业技能考试系统: {
      systemId: "商务",
      category: "test",
      imgList: [],
      content: [
        {
          desc: "  浙江省电子商务职业技能考试系统于2013年立项，2014年底上线。在线考试系统的主要特点是一方面可以动态地管理各种考试系统信息,只要准备好知识面广且海量的题库,就可以按照要求自动生成各种试卷;另一方面,考试时间灵活,用户可以在规定时间段内的任意时间参加考试;另外计算机化的考试的最大特点是阅卷快,系统可以在考试结束时当场给出考试结果,计算机阅卷给了用户的最大的公平感。如何通过一种或多种开发技能高效的设计出这个系统是一个值得考虑的问题。由于现代计算机网络技术的快速发展，信息已遍布全球。再加上现代考试的普及。网络已成为大众化的产物，所以将考试系统与网络相联，是很有意义的。",
        },
        {
          desc: "功能:",
          points: [
            "管理子系统  对试题库管理的部门较少，多为各系部，采用C/S模式，需要安装客户端，供系统管理员和试卷管理员登录，能够管理账户和题库，定义试卷和管理考试，并能够进行手工评卷。 ",
            "考试子系统  考生数量较多，采用B/S模式，无需安装客户端，允许考生通过IE浏览器登录系统，参加考试、查询分数、完成作业；也允许试卷管理员通过IE浏览器登录系统，手工评卷和成绩查询。 ",
          ],
        },
        {
          desc: "用处:",
          points: [
            "动态地管理各种考试信息，只要准备好知识面广且海量的题库，就可以按照要求自动生成各种试卷",
            "考试时间灵活，用户可以在规定时间段内的任意时间参加考试",
            "计算机化的考试的最大特点是阅卷快，系统可以在考试结束时当场给出考试结果，计算机阅卷给了用户的最大的公平感。",
          ],
        },
      ],
    },
  },
  办公自动化系统: {
    天华宾馆办公系统: {
      systemId: "tianhua",
      category: "office",
      imgList: [],
      content: [
        {
          desc: "在我国，酒店（宾馆）业是市场经济中竞争最激烈的行业之一。随着行业竞争的加剧和集团化趋势的加速，酒店（宾馆）需要使用更有效的信息化手段，不断地改善运营方式和管理方式，更加有效地整合企业内部的资源。对企业的知识信息、项目、人力资源等进行全面的管理，并创建畅通无阻的协同工作环境，使整个企业在一个统一的信息平台进行高效的事务处理和分析决策，从而提升企业管理水平，拓展经营空间，降低运营成本，提高管理和决策效率。",
        },
        {
          desc: "天华酒店（宾馆）办公自动化系统是专业针对酒店（宾馆）办公应用开发的软件。在深入分析其办公业务特点和需求的基础上，凭借多年软件开发经验，采用先进技术平台，整合研发的功能完备、安全性高、操作使用简单、稳定可靠的办公自动化系统。",
        },
        {
          desc: "天华酒店（宾馆）办公自动化系统采用B/S架构,即浏览器/服务器架构,B/S架构是最适应Internet时代需求的架构，可以通过天华酒店(宾馆)办公自动化系统，借助Internet和其它各种已有的网络，方便地实现远程办公和异地协同办公功能，从而在广域范围内实现企业一体化管理。",
        },
        {
          desc: "天华酒店(宾馆)办公自动化系统已经在多家酒店(宾馆)持续稳定应用多年, 较好地保证了酒店(宾馆)日常管理服务的需求,使各部门的信息沟通变得流畅。高层管理人员能及时了解各部门主管及成员的信息，了解各成员酒店的实际经营状况、人力状况等经营管理决策信息；并能让员工不断获得有价值的酒店管理、经营与服务支持等方面的信息，受到相关使用单位的好评。",
        },
        {
          desc: "产品优势:",
          points: [
            "充分研究宾馆酒店办公业务，为宾馆酒店量身定制的办公平台解决方案。系统处理日常工作中各部门间的信息往来，如通知、工作联系单、备忘录，相关部门的工作日记，销售部的团队会议接待单发放和跟踪，各部门工作计划总结，宾馆文件发放，收文处理等，并有完整的电子档案系统。",
            "完善的安全机制。系统对人员权限控制和分配进行严格的管理，管理员可根据需要设置操作人员的数据库权限，片区权限，设置不同文件类别的拟稿权限，设置文件的查看、修改、删除权限。",
            "突破地域限制，只要能上因特网就能随时随地开展工作，出差在外也能随时处理公司的办公事务。",
            "系统具有良好的可扩展性及兼容性，灵活地进行模块扩展。",
            "将日常管理纳入系统中，使难以协调的事务管理趋于理性、科学、轻松，系统在充分发挥计算机作用的情况下，保留传统办公的习惯，使系统更易使用。",
          ],
        },
      ],
    },
  },
};

export const casusSimpleList = [
  {
    id: "country",
    category: "未来乡村系统",
    systemList: [
      {
        systemName: "北山口一颗金佛手",
        points: [
          "首页动态化，操作简便",
          "模块智能化，良好地展现村内历史文化、种植产业、所获荣誉等多方面信息",
          "图表多元化，多种方式展现村内产业在各年度的种植、产量增减、销售等情况",
        ],
        systemId: "beishankou",
        imgUrl: require("@/assets/casus/beishankou_cover.png"),
      },
      {
        systemName: "智治澧浦",
        points: [
          "两基础、一中心、N应用",
          "党政机关整体智治、数字政府、数字经济、数字社会、数字法治综合应用",
          "建设数据展示中心等24个子系统应用",
        ],
        systemId: "lipu",
        imgUrl: require("@/assets/casus/lipu_cover.png"),
      },
      {
        systemName: "釜章村乡村数字化项目",
        points: [
          "乡村治理、乡村服务、产业共富的目标",
          "一统三化九场景的指导思想",
          "推动釜章产业振兴、文化振兴、生态振兴、农业振兴",
        ],
        systemId: "fuzhang",
        imgUrl: require("@/assets/casus/fuzhang_cover.png"),
      },
      {
        systemName: "东叶乡村数字化建设项目",
        points: [
          "乡村治理、乡村服务、产业共富的目标",
          "一统三化九场景的指导思想",
          "丰富“我的家园”中东叶村的应用内容",
        ],
        systemId: "dongye",
        imgUrl: require("@/assets/casus/dongye_cover.png"),
      },
    ],
  },
  {
    id: "govement",
    category: "政务管理系统",
    systemList: [
      {
        systemName: "金华市兰溪市工业企业亩产效益综合评价管理系统",
        points: [
          "推进金华市兰溪市经济和信息化局资源要素配置改革",
          "优化经济运行分析监测",
          "实现企业信息共享",
          "提高管理服务效能",
        ],
        systemId: "mujun",
        imgUrl: require("@/assets/casus/mujun_cover.png"),
      },
      {
        systemName: "武义县户籍式企业信息管理系统",
        points: [
          "节约项目申报企业的办事成本",
          "减轻事后处置的成本",
          "全面掌握运营情况",
          "深入服务工业企业亩产效益评价工作",
        ],
        systemId: "huji",
        imgUrl: require("@/assets/default_HD.png"),
      },
      {
        systemName: "金华市企业信息管理平台",
        points: [
          "促进政府主管部门企业监控的时效性和精准性",
          "为主管部门对企业经济运行监测分析提供了方便快捷的渠道",
          "为主管部门实现企业信息共享，提供了企业信息支撑",
          "提高了各级主管部门的服务效能",
          "减轻了企业数据重复申报的工作量",
        ],
        systemId: "qiye",
        imgUrl: require("@/assets/default_HD.png"),
      },
    ],
  },
  {
    id: "garden",
    category: "园区公共服务平台",
    systemList: [
      {
        systemName: "菜鸟红链e园区党建APP",
        points: [
          "开放注册用户在线学习、生成学习记录、学习考核",
          "管理园区各级党支部的信息",
          "开展园区特色活动的组织、报名",
          "展示园区各类先进评选",
          "对接面向社会的人才招聘",
        ],
        systemId: "cainiao",
        imgUrl: require("@/assets/default_HD.png"),
      },
      {
        systemName: "金东区网络经济服务平台",
        points: [
          "良好地展示园区建设、园区服务能力",
          "削除了园区分散带来的管理不便问题",
          "提高区内企业的盈利水平",
          "改善金东区的人才结构和产业形态",
        ],
        systemId: "jingji",
        imgUrl: require("@/assets/casus/jindong_economics_cover.png"),
      },
    ],
  },
  {
    id: "community",
    category: "社区服务管理系统",
    systemList: [
      {
        systemName: "金都美地社区",
        points: [
          "安全可靠权限分配灵活，保密严格",
          "数据一次录入后自动关联、分类以及汇总，节约工作时间",
          "操作简便，满足基层人员不同水平的应用",
          "可扩展性强，可以随着社区业务的调整变化",
        ],
        systemId: "jindu",
        imgUrl: require("@/assets/casus/jindu_cover.png"),
      },
    ],
  },
  {
    id: "test",
    category: "在线考试系统",
    systemList: [
      {
        systemName: "浙江省国际服务外包考试系统",
        points: [
          {
            desc: "信息技术外包服务平台：截止2017年底，共计培训学员4100多人次",
            points: [],
          },
          {
            desc: "服务外包综合平台：",
            points: [
              "截至2017年累计培训各类人才30000多人",
              "为52家企业提供国际认证培训咨询服务",
            ],
          },
          {
            desc: "电商培训平台：",
            points: [
              "截止2017年底助理电子商务师共计培训300余人次",
              "颁发证书250人",
            ],
          },
        ],
        systemId: "guoji",
        imgUrl: require("@/assets/default_HD.png"),
      },
      {
        systemName: "浙江省电子商务职业技能考试系统",
        points: [
          {
            desc: "管理子系统：",
            points: [
              "采用C/S模式",
              "能管理账户和题库，定义试卷和管理考试",
              "能够手工评卷",
            ],
          },
          {
            desc: "考试子系统：",
            points: [
              "采用B/S模式",
              "无需安装客户端",
              "允许考生通过IE浏览器登录、考试、查询分数、完成作业",
              "允许试卷管理员通过IE浏览器登录系统、手工评卷和查询成绩",
            ],
          },
        ],
        systemId: "shangwu",
        imgUrl: require("@/assets/default_HD.png"),
      },
    ],
  },
  {
    id: "office",
    category: "办公自动化系统",
    systemList: [
      {
        systemName: "天华宾馆办公系统",
        points: [
          "为宾馆酒店量身定制的办公平台解决方案",
          "完善的安全机制",
          "突破地域限制",
          "具有良好的可扩展性及兼容性，灵活地进行模块扩展",
        ],
        systemId: "tianhua",
        imgUrl: require("@/assets/default_HD.png"),
      },
    ],
  },
];
